const CsvColumns = [
    {
      name: 'email',
      field: 'userId',
      source: 'user'
    },
    {
      name: 'cadUserName',
      field: 'cadUserName',
      source: 'user'
    },
    {
      name: 'tenantId',
      field: 'tenantId',
      source: 'tenant'
    },
    {
      name: 'agencyId',
      field: 'cadAgencyAlias',
      importField: 'cadUserAgencyKey',
      source: 'tenant'
    },
    {
      name: 'customerId',
      field: 'customerId',
      source: 'tenant'
    }
  ];
export { CsvColumns };
