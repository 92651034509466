/*
 * Copyright 2020 Motorola Solutions, Inc.
 * All Rights Reserved.
 * Motorola Solutions Confidential Restricted
 */

import { IAM_ENVS, IamAuthGrantConfig } from '@msi/commandcentral-user-authentication';

const CLIENT_ID = 'CCAdmin';
const SCOPES = ['CCAdmin', 'openid',
  'profile',
  'email',
  'CCRuleService'];

export function getAppSpecificIamConfig() {
  console.log(`getAppSpecificIamConfig:: Starting`);
  const origin = window.origin;
  const host = new URL(origin).host;
  let type;

  console.log(`getAppSpecificIamConfig:: Origin set to ${origin}`);
  console.log(`getAppSpecificIamConfig:: Host set to ${host}`);

  if (host.includes('localhost')) {
    type = 'local';
  } else {
    type = host.split('.')[1];
  }
  let IDM;
  const parent = document.referrer;
  console.log(`getAppSpecificIamConfig:: Parent set to ${parent} for host: ${host}, and for type: ${type}`);

  switch (type) {
    case 'local':
    case 'dev':
      if (parent.includes('admin2')) {
        IDM = IAM_ENVS.idmmaster;
        sessionStorage.setItem('Admin', 'admin2');
      } else {
        const av = sessionStorage.getItem('Admin');
        if (av === 'admin2') {
          IDM = IAM_ENVS.idmmaster;
        } else {
          IDM = 'https://idmmaster.imw.motorolasolutions.com';
        }
      }
      break;
    case 'stage':
    case 'usgov-stage':
      if (parent.includes('admin2')) {
        IDM = IAM_ENVS.idmmaster;
        sessionStorage.setItem('Admin', 'admin2');
      } else {
        const av = sessionStorage.getItem('Admin');
        if (av === 'admin2') {
          IDM = IAM_ENVS.idmmaster;
        } else {
          IDM = 'https://idmmaster.imw.motorolasolutions.com';
        }
      }
      break;
    case 'commandcentral':
    case 'ent':
    case 'prod':
      IDM = IAM_ENVS.idmUS;
      break;
    case 'usgov':
      IDM = IAM_ENVS.idmUS;
      break;
    default:
      IDM = IAM_ENVS.idmUS;
      break;
  }
  console.log(`getAppSpecificIamConfig:: Set IDM as: ${IDM}`);

  const config: IamAuthGrantConfig = {
    scopes: SCOPES,
    clientId: CLIENT_ID,
    developmentMode: false,
    singlePreprod: true,
    idmUrl: IDM
  };
  console.log(`getAppSpecificIamConfig::AppSpecificIamConfig returning ${JSON.stringify(config, null, 3)} and Ending`);
  return config;
}
