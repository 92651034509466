import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(private location: Location) { }
  settingsForm: FormGroup;

  ngOnInit() {
    this.settingsForm = new FormGroup({token: new FormControl(localStorage.getItem('token') || '')});
  }

  saveToken() {
    const token = this.settingsForm.get('token').value.replace(/\s/g, '');
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
    this.back();
  }
  back() {
    this.location.back();
  }

}
