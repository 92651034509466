/*
 * Copyright 2021 Motorola Solutions, Inc.
 * All Rights Reserved.
 * Motorola Solutions Confidential Restricted
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@msi/commandcentral-user-authentication';

@Injectable({
  providedIn: 'root'
})
export class ConfirmAuthGuardService implements CanActivate, CanActivateChild {
  private routerPath: string[];

  constructor(protected router: Router, private authenticationService: AuthenticationService) {
    console.log(`ConfirmAuthGuardService::constructor()`);
  }

  private setRouterPath(route: ActivatedRouteSnapshot): void {
    console.log(`ConfirmAuthGuardService::setRouterPath`);
    this.routerPath = route && route[`_urlSegment`] && route[`_urlSegment`].segments
        ? [...route[`_urlSegment`].segments.map((url) => url.path)] : undefined;
  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    console.log(`ConfirmAuthGuardService::canActivate`);
    this.setRouterPath(route);
    return this.isAuthenticated();
  }

  // tslint:disable-next-line:no-identical-functions
  public async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    console.log(`ConfirmAuthGuardService::canActivateChild`);
    this.setRouterPath(route);
    return this.isAuthenticated();
  }

  public async isAuthenticated(): Promise<boolean> {
    console.log(`ConfirmAuthGuardService::isAuthenticated`);
    const savedToken = await this.authenticationService.authenticate();
    return !!savedToken;
  }
}
