import { Component, OnDestroy, OnInit } from '@angular/core';
import { TenantMappingService } from '../services/tenant-mapping.service';
import { TokenService } from '../services/token.service';
import { Subscription } from 'rxjs';
import { AsyncTask } from '../model/AsyncTask';
import { EnvService } from '../services/env.service';
import { CcDriveOnboardService } from '../services/ccdrive-onboard.service';

@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.scss'],
})
export class MainViewComponent implements OnInit, OnDestroy {
  ccTenant;
  P1CustomerId;
  ccTenantLoader: Subscription;
  isReadyLoader: Subscription;
  showTenantMapping = false;
  isCustomerAdmin = false;
  isCustomerAdminLoader: Subscription;
  hasTenantMapping = false;
  hasTenantMappingLoader: Subscription;
  hasPendingAsyncTasks = false;
  PendingTaskMap: Map<string, AsyncTask>;
  isCcDriveOnboardSuccess = false;
  ccDriveOnboardErrorMessage = `  Verifying incident attachment onboard status ...  `;
  isCcDriveOnboardEnabled = false;
  constructor(private tenantMappingService: TenantMappingService, private tokenService: TokenService,
              private envService: EnvService, private ccDriveOnboardService: CcDriveOnboardService ) {}

  ngOnInit() {
    this.PendingTaskMap = new Map<string, AsyncTask>();
    this.ccTenantLoader = this.tokenService.currTenant.subscribe((tenantId) => {
      this.ccTenant = tenantId;
    });
    // Handles response from AMS create folder call
    this.ccDriveOnboardService.amsCreateFolderResponse.subscribe(responseCode => {
      this.handleAmsCreateFolderResponse(responseCode);
    });
    // Handles response from ccDrive onboard call
    this.ccDriveOnboardService.ccDriveOnboardResponse.subscribe(responseCode => {
      this.handleCcDriveOnboardResponse(responseCode);
    });
    // Listen to ccAgencyData when available to trigger ccDrive onboarding
    this.envService.CCAdminCustomerConfiguration.subscribe(ccAgencyData => {
      if (ccAgencyData) {
        this.isCcDriveOnboardEnabled = ccAgencyData.isEnableIncidentAttachment;
        // Call ccDrive Onboarding when it is isReady
        if (ccAgencyData.customerId && this.isCcDriveOnboardEnabled) {
          this.P1CustomerId = ccAgencyData.customerId;
          this.onboardCcDrive(this.P1CustomerId);
        }
      }
    });
    // There is configuration in CCAdmin only, need to make another call for tenantMappint from DB
    this.isReadyLoader = this.tokenService.isReady.subscribe((isReady) => {
      if (isReady === true) {
        this.showTenantMapping = true;
      }
    });

    this.isCustomerAdminLoader = this.envService.isCustomerAdmin.subscribe(isAdmin => {
      this.isCustomerAdmin = isAdmin;
    });
    this.hasTenantMappingLoader = this.tenantMappingService.tenantMapping.subscribe(data => {
      this.hasTenantMapping = true;
      this.P1CustomerId = data.customerId;

      // this.getPendingAsyncTasks();
    }, err => {
      this.hasTenantMapping = false;
      console.log(err); });
  }

  ngOnDestroy() {
    if (this.ccTenantLoader !== undefined) {
      this.ccTenantLoader.unsubscribe();
    }
    if (this.isReadyLoader !== undefined) {
      this.isReadyLoader.unsubscribe();
    }
    if (this.isCustomerAdminLoader !== undefined) {
      this.isCustomerAdminLoader.unsubscribe();
    }
    if (this.hasTenantMappingLoader !== undefined) {
      this.hasTenantMappingLoader.unsubscribe();
    }
  }

  // Trigger Onboarding CcDrive resources
  private onboardCcDrive(customerId: string) {
    return this.ccDriveOnboardService.onboardCcDrive(customerId);
  }

  // Handle responses from CcDrive onobard call
  private handleCcDriveOnboardResponse(responseCode: number) {
    // Based upon response code to continue create AMS folder for host agency
    if (responseCode === 200 || responseCode === 201) {
      // Call AMS onboard
      this.createAmsFolder(this.P1CustomerId, this.tokenService.tenantId);
      this.isCcDriveOnboardSuccess = true;
    } else if (responseCode === 424) {
      // customer host agency not found or ccDrive Service was not added to the host agency
      console.log(`Incident attachment onboard failed with error code: ` + responseCode +
        `. Please verify if ccDrive service is added to the customer host agency.`);
      this.isCcDriveOnboardSuccess = false;
      this.ccDriveOnboardErrorMessage = `  Incident attachment onboard failed. ` +
        `Please verify if ccDrive service is added to the customer host agency.  `;
    } else if (responseCode === 403) {
      // Not enough permission to onboard
      console.log(`Incident attachment onboard failed with error code: ` + responseCode +
        `. Please make sure you have sufficient rights to perform CADCloud admin tasks.`);
      this.isCcDriveOnboardSuccess = false;
      this.ccDriveOnboardErrorMessage = `  Unable to verify incident attachment onboard status. ` +
        `Please make sure you have sufficient rights to perform CADCloud admin tasks.  `;
    } else {
      // Not success due to error code
      console.error(`Incident attachment onboard failed with error code: ` + responseCode);
      this.isCcDriveOnboardSuccess = false;
      this.ccDriveOnboardErrorMessage = `  Incident attachment onboard failed with error code: ` + responseCode + `  `;
    }
  }

  // Create AMS folder
  private createAmsFolder(customerId: string, tenantId: string)  {
    return this.ccDriveOnboardService.createAmsFolder(customerId, tenantId);
  }

  // Handle responses from AMS create folder call
  private handleAmsCreateFolderResponse(responseCode: number) {
    if (responseCode === 200 || responseCode === 201) {
      console.log(`AMS folders for inicdent attachment were created successfully for customer: ` +
        this.P1CustomerId + `; tenant: ` +  this.tokenService.tenantId + `.`);
      this.isCcDriveOnboardSuccess = true;
    } else if (responseCode === 424) {
      // customer host agency not found or ccDrive Service was not added to the host agency
      console.log(`AMS folder creation failed with error code: ` + responseCode + ` for customer: ` +
        this.P1CustomerId + `; tenant: ` +  this.tokenService.tenantId +
        ` . Please verify if CADCloud service for this agency is configured properly.`);
      this.isCcDriveOnboardSuccess = false;
      this.ccDriveOnboardErrorMessage = `  Incident attachment AMS folder creation failed. ` +
        `Please verify if CADCloud service for this agency is configured properly.  `;
    } else if (responseCode === 403) {
      // Not enough permission to create ams folder
      console.log(`AMS folder creation failed with error code: ` + responseCode +
        `. Please make sure you have sufficient rights to perform CADCloud admin tasks.`);
      this.isCcDriveOnboardSuccess = false;
      this.ccDriveOnboardErrorMessage = `  Unable to verify incident attachment onboard status. ` +
        `Please make sure you have sufficient rights to perform CADCloud admin tasks.  `;
    } else {
      // Not success due to error code
      console.error(`AMS folder creation failed with error code: ` + responseCode);
      this.isCcDriveOnboardSuccess = false;
      this.ccDriveOnboardErrorMessage = `  Incident attachment AMS folder creation failed with error code: ` + responseCode + `  `;
    }
  }
  // TO DO:

  // getPendingAsyncTasks()
  // {
  //   testCorrelationIds = ["7aa0216e-0593-4c1e-8e4d-096e3c9061fd","28cb97db-6793-4763-afcc-499b9c29bf29",
  // "37fc080d-5dce-458e-982b-139041203598","e407431d-95fc-4094-a61f-9ff7f104d472"];
  //   testCorrelationIds.forEach(correlationId => {
  //     this.PendingTaskMap.set(correlationId,new AsyncTask());
  //     this.syncService.CheckPendingAsyncTaskStatus(correlationId).toPromise().then(res=>
  //       {
  //         let data:any;
  //         data = res;
  //         console.log(data)
  //       })
  //   });
  //   let data:any;
  //   this.PendingTaskMap = new Map<string,AsyncTask>();

  //   var asyncTask = {status:SyncStatus.Progressing,correlationId:'887a12fc-3771-49a1-a0df-53376d4ef96e',
  // totalCount:100,finishedCount:67,ratio:67,type:"Time Zero Sync",totalIncidentCount:50,finishedIncidentCount:47,
  // totalUnitCount:50,finishedUnitCount:20};
  //   this.PendingTaskMap.set(asyncTask.correlationId,asyncTask);
  //   var asyncTask2 = {status:SyncStatus.Finished,correlationId:'05e3350b-b815-0006-1230-009520201002',
  // totalCount:100,finishedCount:100,ratio:100,type:"Delta Sync",totalIncidentCount:50,finishedIncidentCount:50,
  // totalUnitCount:50,finishedUnitCount:50};
  //   this.PendingTaskMap.set(asyncTask2.correlationId,asyncTask2);
  //   this.hasPendingAsyncTasks = true;
  // }
}
