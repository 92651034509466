import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { UserMapping, CADUser, CCUser, TenantMapping } from '../model/commonDefinitions';
import { EnvService } from './env.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TenantMappingService } from './tenant-mapping.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private userMappingsUrl: string;
  private ccUsersUrl: string;
  private cadUsersUrl: string;
  private tenantMappingsUrl: string;
  private tenantMapping: TenantMapping;

  constructor(private http: HttpClient, env: EnvService, private tenantMappingService: TenantMappingService) {
    console.log('Begin - Constructor of TenantMappingService');
    const baseUrl = env.getCadCloudBaseUrl();
    this.tenantMappingsUrl = baseUrl + 'mappings/tenant';
    this.cadUsersUrl = baseUrl + 'settings/cad/user/';
    this.ccUsersUrl = baseUrl + 'settings/cc/user';
    this.userMappingsUrl = baseUrl + 'mappings/user';
    console.log('After setting baseurl of TenantMappingService' + this.tenantMappingsUrl);
    this.tenantMappingService.tenantMapping.subscribe(m => {
      this.tenantMapping = m;
    });
  }

  deleteUserMapping(userId: string): Observable<any> {
    console.log('delete mapping: ', userId);
    return this.http.delete(this.userMappingsUrl + '/' + userId).pipe(
      tap(_ => console.log(`delete user mapping result: ` + _)));
  }

  deleteUserMappingByUuid(user: CCUser): Observable<any> {
    console.log('delete mapping: ', user.id);
    const headers = new HttpHeaders()
      .append('tenantId', this.tenantMapping.tenantId)
      .append('customerId', this.tenantMapping.customerId);
    return this.http.delete(this.userMappingsUrl + '/uuid/' + user.uuid, {headers}).pipe(
      tap(_ => console.log(`delete user mapping result: ` + _)));
  }

  private handleGracefullyFailedRequest<T>(source: Observable<T>): Observable<T | any> {
    return source.pipe(
      catchError(err => {
        console.log(`Request failed with error: ${err.status}`);
        return of([]);
    }));
  }

  getUserMappings(): Observable<UserMapping[]> {
    const headers = new HttpHeaders().append('customerId', this.tenantMapping.customerId);
    return this.http.get<UserMapping[]>(this.tenantMappingsUrl + '/' + this.tenantMapping.tenantId + '/user', {headers})
    .pipe(this.handleGracefullyFailedRequest)
    .pipe(tap(_ => { console.log('---- 2 ---- found user mappings:'); console.log(_); }));
  }

  getCcUsers(): Observable<CCUser[]> {
    return this.http.get<CCUser[]>(this.ccUsersUrl + '/' + this.tenantMapping.tenantId)
    .pipe(this.handleGracefullyFailedRequest)
    .pipe(tap(_ => { console.log('---- 3 ---- found users:'); console.log(_); }));
  }

  getCadUsers(): Observable<CADUser[]> {
    return this.http.get<CADUser[]>(this.cadUsersUrl + this.tenantMapping.tenantId)
    .pipe(this.handleGracefullyFailedRequest)
    .pipe(tap(_ => { console.log('---- 1 ---- found CAD users:'); console.log(_); }));
  }

  storeUserMapping(mapping: UserMapping, isNew: boolean): Observable<any> {
    const saveMethod$ = isNew ? this.http.post(this.userMappingsUrl, mapping) :
      this.http.put(this.userMappingsUrl, mapping);
    mapping.tenantId = this.tenantMapping.tenantId;
    mapping.customerId = this.tenantMapping.customerId;
    console.log('user mapping to save');
    console.log(mapping);
    return saveMethod$.pipe(
      tap(console.log),
      catchError(err => {
        console.log('--- within ---', err.status);
        if (err.status === 409 && isNew === true) {
          console.log('conflict detected - some discrepancy, but lets try overwriting this...');
          return this.storeUserMapping(mapping, false);
        }
        return throwError(err);
      })
    );
  }
}
