import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { UserMappingService } from '../services/user-mapping.service';
import { ExporterService } from '../services/exporter.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { RowState } from '../model/RowState';
import { EditableCCUser } from '../model/EditableCCUser';
import { CadUserState } from '../model/CadUserState';
import { CadUsersService } from '../services/cad-users.service';
import { filter } from 'rxjs/operators';

class NestedMatTableDataSource<T> extends MatTableDataSource<T> {

  constructor(initialData: T[] = []) {
    super(initialData);
  }

  sortingDataAccessor =
    (data: T, sortHeaderId: string): string | number => {
      const propPath = sortHeaderId.split('.');
      let value: any = propPath
        .reduce((curObj, property) => curObj[property], data);
      if (typeof value === 'string') {
        value = value.toLowerCase();
      }
      if (value === null) {
        value = 'AAAAAAAIwannabefirst';
      }
      return !isNaN(value) ? Number(value) : value;
    }
}

@Component({
  selector: 'app-user-mapping',
  templateUrl: './user-mapping.component.html',
  styleUrls: ['./user-mapping.component.scss']
})
export class UserMappingComponent implements OnInit {

  rowState = RowState;
  state = CadUserState;
  displayedColumns: string[] = ['user.id', 'user.lastName', 'mapping.cadUserName', 'action'];
  dataSource: NestedMatTableDataSource<EditableCCUser>;

  @Input() ccTenantId: string;
  @ViewChild('fileInput', { static: false }) inputFileName: ElementRef;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  @ViewChild(MatSort, {static: false}) set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
    console.log(this.dataSource);
  }

  @ViewChild(MatPaginator, {static: false}) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.dataSource.paginator = this.paginator;
  }

  constructor(public mappingService: UserMappingService, public cadUsersService: CadUsersService,
              public exporter: ExporterService ) {
    this.dataSource = new NestedMatTableDataSource();
  }

  fileChanged(event) {
    this.exporter.importMappings(event.target.files[0]);
    this.inputFileName.nativeElement.value = '';
  }

  ngOnInit() {
    this.mappingService.initialized$.pipe(filter(a => a === true))
    .subscribe(_ => this.dataSource.data = this.mappingService.displayableMappings);
  }

}
