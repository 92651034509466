import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeltaSyncRequestModel } from '../../model/DeltaSyncRequestModel';
import { SyncStatus } from '../../model/SyncStatus';
import { SyncService } from '../../services/sync.service';


@Component({
  selector: 'app-delta-sync-dialog',
  templateUrl: './delta-sync-dialog.component.html',
  styleUrls: ['./delta-sync-dialog.component.scss'],
  providers: [ DatePipe ]
})
export class DeltaSyncDialogComponent implements OnInit {
  public color: ThemePalette = 'primary';
  private DateTimeFormat = 'yyyy-MM-dd HH:mm:ss a';
  status: SyncStatus;
  deltaSyncFormGroup: FormGroup;
  deltaSyncRequest: DeltaSyncRequestModel;
  constructor(public dialogRef: MatDialogRef<DeltaSyncDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,
              private snackBar: MatSnackBar, private datePipe: DatePipe, private syncService: SyncService) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.status = SyncStatus.Initial;
    this._initializeDeltaSyncFormGroup();
  }


  onNoClick(): void {
    this.snackBar.dismiss();
    this.dialogRef.close();
  }

  startSync(): void {
    if (!this._convertToRequest()) {
      return;
    }
    this.status = SyncStatus.Progressing;
    this.syncService.DeltaASync(this.deltaSyncRequest).toPromise().then(() => {
      this.status = SyncStatus.Finished;
      this.snackBar.open('Synchronized Successfully!!!', 'Close', { duration: 8000, panelClass: ['successSnackBarColor']});
    }, err => {
      let errMsg = '';
      if (err.status === 400) {
        errMsg = err.error;
      }
      this.status = SyncStatus.Initial;
      this.snackBar.open('Failed to synchronize data. ' + errMsg, 'Close', { duration: 8000, panelClass: ['errorSnackBarColor']});
    });
  }

  private _initializeDeltaSyncFormGroup(): void {
    const defualtStartDate = new Date();
    defualtStartDate.setDate(defualtStartDate.getDate() - 1);
    this.deltaSyncFormGroup = new FormGroup(
      {
        FromUpdatedDateTime: new FormControl(defualtStartDate, [Validators.required]),
        ToUpdatedDateTime: new FormControl(undefined),
        IsIncludeClosedIncidents: new FormControl(false, [Validators.required]),
        ToCurrentDateTime: new FormControl(true)
      });
  }

  private _convertToRequest(): boolean {
    if (this.deltaSyncFormGroup.controls.ToCurrentDateTime.value === true || !this.deltaSyncFormGroup.controls.ToUpdatedDateTime.value) {
      this.deltaSyncFormGroup.controls.ToUpdatedDateTime.setValue(new Date());
    }
    const startTime = this.datePipe.transform(this.deltaSyncFormGroup.controls.FromUpdatedDateTime.value, this.DateTimeFormat);
    const endTime = this.datePipe.transform(this.deltaSyncFormGroup.controls.ToUpdatedDateTime.value, this.DateTimeFormat);
    const startTimeObj = new Date(startTime);
    const endTimeObj = new Date(endTime);
    if (startTimeObj.getTime() >= endTimeObj.getTime()) {
      this.snackBar.open('Invalid Time Range!', 'Close', { duration: 5000, panelClass: ['errorSnackBarColor']});
      return false;
    }
    this.deltaSyncRequest = {
      fromUpdatedTime: startTime,
      toUpdatedTime: endTime,
      isIncludeClosedIncidents: this.deltaSyncFormGroup.controls.IsIncludeClosedIncidents.value
    };
    console.log(this.deltaSyncRequest);
    return true;
  }

}
