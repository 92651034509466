import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SiteNotification } from '../../model/SiteNotification';
import { SyncService } from '../../services/sync.service';

@Component({
  selector: 'app-site-notification-dialog',
  templateUrl: './site-notification-dialog.component.html',
  styleUrls: ['./site-notification-dialog.component.scss']
})
export class SiteNotificationDialogComponent implements OnInit {
  message: string;
  typeOfSiteNotification: SiteNotification;
  constructor(public dialogRef: MatDialogRef<SiteNotificationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: SiteNotification,
              private snackBar: MatSnackBar, private syncService: SyncService) {
    this.typeOfSiteNotification = data;
    this.message = data.toString();
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.snackBar.dismiss();
    this.dialogRef.close();
  }

  // Site Up / Down Notification
  sendSiteNotification(): void {
    let siteNotificationType;
    if (this.message === SiteNotification.SiteDown) {
      siteNotificationType = 'CustomerOnPremDown';
    }
    if (this.message === SiteNotification.SiteUp) {
      siteNotificationType = 'CustomerOnPremUp';
    }
    this.dialogRef.close();
    this.syncService.SendSiteNotification(siteNotificationType).toPromise().then(() => {
        this.snackBar.open(`${this.message} Notification is sent.`, 'Close', {duration: 8000, panelClass: ['successSnackBarColor']});
      }, err => {
        console.log(err);
        this.snackBar.open(`Failed to send ${this.message} Notification.`, 'Close', { duration: 8000, panelClass: ['errorSnackBarColor']});
      });
  }


}
