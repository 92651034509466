import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvTypeService } from './envType.service';
import { EnvType } from './envType.service';
import { CCAgency } from '../model/commonDefinitions';
import { ReplaySubject, Subject, throwError, BehaviorSubject } from 'rxjs';

const CADCLOUD_ADMIN_BASE_URL = 'https://cadcloud.__ENV__commandcentral.com/api/admin/v1/';
@Injectable({
  providedIn: 'root',
})
export class EnvService {
  private tenantBaseUrlMap: Map<string, string>;
  // Note: This is only be used when the tenantMapping is not exiting but the tenant is root agency!
  CCAdminCustomerConfiguration: BehaviorSubject<CCAgency> = new BehaviorSubject<CCAgency>(null);
  isCustomerAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient, private envType: EnvTypeService) {
    console.log('Constructor EnvService ');
    this.tenantBaseUrlMap = new Map<string, string>();
  }

  loadTenantUrl(result, ccTenant: string) {
    console.log('Enter EnvService:: loadTenantUrl for tenantId = ' + ccTenant);
    if (ccTenant) {
      if (!this.tenantBaseUrlMap.has(ccTenant)) {
        const defaultUrl = this.getCadCloudEnvUrl(this.envType.type.toLowerCase());
        this.setAdminApiEnv(result, defaultUrl, ccTenant);
      } else {
        console.log('Tenant already exist in EnvService:: loadTenantUrl for ccTenantId = ' + ccTenant);
        result('Found it');
      }
    }
  }

  isTenantUrlLoaded(result) {
    const ccTenant = sessionStorage.getItem('tenant');
    this.loadTenantUrl(result, ccTenant);
  }

  getCadCloudBaseUrl(): string {
    const ccTenant = sessionStorage.getItem('tenant');
    console.log('Enter EnvService:: getCadCloudBaseUrl for tenantId = ' + ccTenant);
    const baseUrl = this.tenantBaseUrlMap.has(ccTenant)
      ? this.tenantBaseUrlMap.get(ccTenant)
      : this.getCadCloudEnvUrl(this.envType.type.toLowerCase());
    console.log('Exiting EnvService:: getCadCloudBaseUrl for tenantId = ' + ccTenant + 'Base Url : ' + baseUrl);
    return baseUrl;
  }

  private getCadCloudEnvUrl(env): string {
    const envPrefixes = new Map()
      .set(EnvType.Local, 'dev.')
      .set(EnvType.Dev, 'dev.')
      .set(EnvType.QA, 'stage.')
      .set(EnvType.QS, 'stage.')
      .set(EnvType.PI, 'pi.')
      .set(EnvType.Stage, 'usgov-stage.')
      .set(EnvType.StageUsgov, 'usgov-stage.')
      .set(EnvType.Prod, 'usgov.')
      .set(EnvType.ProdUs, 'usgov.')
      .set(EnvType.ProdUsgov, 'usgov.');

    const url = CADCLOUD_ADMIN_BASE_URL.replace('__ENV__', envPrefixes.get(env));
    console.log(`getCadCloudEnvUrl returned the following url ${url} for environment ${env}`);
    return url;
  }

  private setAdminApiEnv(updateEnv, defaultUrl, ccTenant: string) {
    console.log('start of setAdminApiEnv with default url ' + defaultUrl);
    const promise = this.http.get<CCAgency>(defaultUrl + 'settings/cc/agency/' + ccTenant).toPromise();
    promise
      .then((data) => {
        console.log('Successfully got the environment: ' + JSON.stringify(data));
        if (data && data.environment) {
          const currentUrl = this.getCadCloudEnvUrl(data.environment);
          console.log('The current url based on the environment ' + currentUrl +
          'ccTenantId (ccAgencyId/ccCustomerId) : ' + ccTenant);
          if (!this.tenantBaseUrlMap.has(ccTenant)) {
            this.tenantBaseUrlMap.set(ccTenant, currentUrl);
            console.log(
              'Set the value in map for current url based on the environment' + currentUrl +
              'ccTenantId (ccAgencyId/ccCustomerId) : ' + ccTenant
            );
          }
          updateEnv(currentUrl);
        } else {
          updateEnv(defaultUrl);
        }
        this.CCAdminCustomerConfiguration.next(data);
        this.checkTenantRole(data);
      })
      .catch((error) => {
        console.error('Could not get the environment with exception: ' + JSON.stringify(error));
        updateEnv(defaultUrl);
      });
  }

  getUrlParameter(parameter: string): string {
    console.log('start of getUrlParameter' + parameter);
    let loop;
    const url = window.location.href;
    const search = url.indexOf('?');
    if (search < 0) {
      return '';
    }
    const searchPhrase = parameter + '=';
    const parsed = url.substr(search + 1).split('&');
    const count = parsed.length;
    for (loop = 0; loop < count; loop++) {
      if (parsed[loop].substr(0, searchPhrase.length) === searchPhrase) {
        return decodeURI(parsed[loop].substr(searchPhrase.length));
      }
    }
    return '';
  }

  private checkTenantRole(data: any) {
    if (data && data.id.toLowerCase() === data.customerId.toLowerCase()) {
      this.isCustomerAdmin.next(true);
    } else {
      this.isCustomerAdmin.next(false);
    }
  }

  public getCustomerCadType(p1CustomerId: string) {
    const URL = this.getCadCloudBaseUrl() + 'settings/cad/agency/' + p1CustomerId;
    const opt = {headers: new HttpHeaders({'Content-Type': 'application/json', customerId: p1CustomerId})};
    return this.http.get(URL, opt);
  }
}
