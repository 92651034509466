import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { SyncStatus } from '../../model/SyncStatus';
import { UnitModel } from '../../model/UnitModel';
import { SyncService } from '../../services/sync.service';

@Component({
  selector: 'app-unit-sync-dialog',
  templateUrl: './unit-sync-dialog.component.html',
  styleUrls: ['./unit-sync-dialog.component.scss']
})
export class UnitSyncDialogComponent implements OnInit {
  status: SyncStatus;
  unitId: FormControl;
  dataSource: MatTableDataSource<UnitModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['agencyKey', 'callSign', 'key', 'action'];
  constructor(public dialogRef: MatDialogRef<UnitSyncDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,
              private snackBar: MatSnackBar, private syncService: SyncService) {
    dialogRef.disableClose = true;
  }
  ngOnInit(): void {
    this.status = SyncStatus.Initial;
    this.unitId = new FormControl(undefined, [Validators.required]);
    this.syncService.GetUnitsByTenantId().subscribe(observeObj => {
        let data: any;
        data = observeObj;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      });
  }

  onNoClick(): void {
    this.snackBar.dismiss();
    this.dialogRef.close();
  }

  startSync(): void {
    this.status = SyncStatus.Progressing;
    this.snackBar.open('The system is synchronizing...');
    this.syncService.SyncByUnitId(this.unitId.value).toPromise().then(() => {
        this.status = SyncStatus.Finished;
        this.snackBar.open('Synchronized Successfully!!!', 'Close', { duration: 8000, panelClass: ['successSnackBarColor']});
      }, err => {
        this.status = SyncStatus.Initial;
        this.snackBar.open('Failed to synchronize data.', 'Close', { duration: 8000, panelClass: ['errorSnackBarColor']});
      });
  }
  fillUnitData(unitKey) {
    this.unitId.setValue(unitKey);
  }

}
