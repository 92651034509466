import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { IncidentModel } from '../../model/IncidentModel';
import { SyncStatus } from '../../model/SyncStatus';
import { SyncService } from '../../services/sync.service';
@Component({
  selector: 'app-incident-sync-dialog',
  templateUrl: './incident-sync-dialog.component.html',
  styleUrls: ['./incident-sync-dialog.component.scss']
})
export class IncidentSyncDialogComponent implements OnInit {
  status: SyncStatus;
  incidentId: FormControl;
  dataSource: MatTableDataSource<IncidentModel>;
  displayedColumns: string[] = ['alias', 'key', 'action'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(public dialogRef: MatDialogRef<IncidentSyncDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,
              private snackBar: MatSnackBar, private syncService: SyncService) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.status = SyncStatus.Initial;
    this.incidentId = new FormControl(undefined, [Validators.required]);
    this.syncService.GetIncidentsByTenantId().subscribe(observeObj => {
        let data: any;
        data = observeObj;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      });
  }

  onNoClick(): void {
    this.snackBar.dismiss();
    this.dialogRef.close();
  }

  startSync(): void {
    this.status = SyncStatus.Progressing;
    this.snackBar.open('The system is synchronizing...');
    this.syncService.SyncByIncidentId(this.incidentId.value).toPromise().then(() => {
        this.status = SyncStatus.Finished;
        this.snackBar.open('Synchronized Successfully!!!', 'Close', { duration: 8000, panelClass: ['successSnackBarColor']});
      }, err => {
        this.status = SyncStatus.Initial;
        this.snackBar.open('Failed to synchronize data.', 'Close', { duration: 8000, panelClass: ['errorSnackBarColor']});
      });
  }
  fillUnitData(incidentKey) {
    this.incidentId.setValue(incidentKey);
  }


}
