import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CadType } from '../model/commonDefinitions';
import { AzureResourcesService } from '../services/azure-resources.service';
import { TenantMappingService } from '../services/tenant-mapping.service';
import { EnvService } from '../services/env.service';
import { Subscription, of } from 'rxjs';
import { TimeZeroSyncDialogComponent } from '../widget-dialogs/time-zero-sync-dialog/time-zero-sync-dialog.component';
import { MatDialog } from '@angular/material/dialog';

class ActionButton {
  name: string;
  callback: () => void;
  isActive: boolean;
}

class ErrorMessageDetails {
  title: string;
  description: string;
  actions?: ActionButton[];
}

@Component({
  selector: 'app-tenant-mapping-loader',
  templateUrl: './tenant-mapping-loader.component.html',
  styleUrls: ['./tenant-mapping-loader.component.scss']
})
export class TenantMappingLoaderComponent implements OnInit, OnDestroy {
  // ccTenantId = CCAdmin CustomerId = CCAdmin AgencyId = P1 customerID + cadAgencyAlias
  // The reason why CCAdmin CustomerId = CCAdmin AgencyId is because all the customers for CAD Cloud in CCAdmin are flat customers.
  // In other word, only one root agency inside the customer
  @Input() ccTenantId: string;
  P1CustomerId;
  selectedCustomerType = 'All';
  environmentInputText = '';
  clientIdsText = '';
  isCustomerAdmin = false;
  isCustomerAdminLoader: Subscription;
  ccAdminCustomerConfigurationLoader: Subscription;
  ccAdminCustomerConfiguration: any;
  CADType;
  error: ErrorMessageDetails;

  customerTypes = [
    { value: 'All', viewValue: 'All' },
    { value: 'PassThru', viewValue: 'PassThru' },
    { value: 'ClientOnly', viewValue: 'ClientOnly' },
    { value: 'Disabled', viewValue: 'Disabled' }
  ];

  constructor(private envService: EnvService, private azureResources: AzureResourcesService,
              private tenantMappingService: TenantMappingService, private dialog: MatDialog) {
  }
  ngOnDestroy(): void {
    if (this.isCustomerAdminLoader !== undefined) {
      this.isCustomerAdminLoader.unsubscribe();
    }
    if (this.ccAdminCustomerConfigurationLoader !== undefined) {
      this.ccAdminCustomerConfigurationLoader.unsubscribe();
    }
  }

  ngOnInit() {
    console.log('ccTenantId: ' + this.ccTenantId);
    const self = this;
    this.envService.loadTenantUrl((result) => {
      if (result) {
        self.loadTenantMapping();
      } else {
        console.log('TenantMappingLoaderComponent :: ngInit Failed to load the tenant url');
      }
    }, this.ccTenantId);
  }

  private loadTenantMapping() {
    this.isCustomerAdminLoader = this.envService.isCustomerAdmin.subscribe(isAdmin => this.isCustomerAdmin = isAdmin);
    this.tenantMappingService.tenantMapping.subscribe(a => {
      this.P1CustomerId = a.customerId;
      this.CADType = a.cadType;
      this.checkAzureResources(this.P1CustomerId , this.CADType, this.clientIdsText);
    }, err => {
      console.log(err);
      if (err.status === 404) {
        if (this.isCustomerAdmin === false) {
          this.error = {
            title: 'Tenant mapping not configured!',
            description: 'Make sure CadCloud service parameters (OnPrem CAD Host & OnPrem CAD Agency) are configured in CCAdmin\
                          and Provisioning Data is exported to Cloud services.'
          };
        } else {
          this.ccAdminCustomerConfigurationLoader = this.envService.CCAdminCustomerConfiguration.subscribe(data => {
            this.ccAdminCustomerConfiguration = data;
            console.log(this.ccAdminCustomerConfiguration);
            if (this.ccAdminCustomerConfiguration && this.ccAdminCustomerConfiguration.customerId) {
              this.P1CustomerId = this.ccAdminCustomerConfiguration.customerId;
              this.envService.getCustomerCadType(this.P1CustomerId).subscribe(res => {
                  let cadSetting: any;
                  cadSetting = res;
                  if (cadSetting) {
                    this.CADType = cadSetting[0].cadType;
                    this.tenantMappingService.cadTypeSubject.next(this.CADType);
                  }
                  this.checkAzureResources(this.P1CustomerId, this.CADType, this.clientIdsText);
                }, () => {
                  this.error = {
                    title: 'Tenant mapping not configured!',
                    description: 'Make sure CadCloud service parameters (OnPrem CAD Host & OnPrem CAD Agency) are configured in CCAdmin\
                                  and Provisioning Data is exported to Cloud services.'
                  };
                });
              }
            });
        }
      }
    });
    this.tenantMappingService.loadTenantMapping(this.ccTenantId);
  }

  private checkAzureResources(P1CustomerId: string, cadType: CadType, clientIds: string) {
    this.azureResources.loadAzureResources(P1CustomerId).subscribe(
      () => {this.error = null; },
      (error)  => {
        console.log('errorMessage updated', error);
        if (error.status === 404) {
          this.error = {
            title: `CadCloud API resources for CAD customer '${P1CustomerId}' are not found.`,
            description: 'Would you like to create the resources?',
            actions: [
              { name: 'Create', callback: this.createResources.bind(this, P1CustomerId, cadType, clientIds), isActive: true }
            ]
        };
      }}
    );
  }

  public createResources(p1CustomerId: string, cadType: CadType): void {
    this.error.actions.map(a => {a.isActive = false; });
    this.azureResources.createServiceBus(
      p1CustomerId, cadType, this.selectedCustomerType, this.environmentInputText, this.clientIdsText).subscribe(
      () => { console.log('create resource succeeded');
              if (cadType.toUpperCase() === 'CCCAD' || cadType.toUpperCase() === 'P1CLOUDCAD') {
                const dialogRef = this.dialog.open(TimeZeroSyncDialogComponent);
                dialogRef.afterClosed().subscribe(result => { console.log('The dialog was closed');
                });
              }
              this.checkAzureResources(p1CustomerId, cadType, this.clientIdsText); },
      err => {
        console.log('create resource failed');
        this.error.actions.map(a => {a.isActive = true; });
      }
    );
  }
}
