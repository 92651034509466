import { Injectable } from '@angular/core';

export enum EnvType {
  Local = 'local',
  Dev = 'dev',
  QA = 'qa',
  QS = 'qs',
  Stage = 'stage',
  StageUsgov = 'stageusgov',
  Prod = 'prod',
  ProdUs = 'produs',
  ProdUsgov = 'produsgov',
  PI = 'pi'
}
@Injectable({
  providedIn: 'root'
})
export class EnvTypeService {
  get type(): EnvType {
    const hostName = window.location.hostname;
    console.log('Hostname: ' + hostName);
    const type = hostName.split('.')[1];

    console.log('Inside get environment type' + type);

    switch (type) {
      case 'local':
        return EnvType.Local;
      case 'dev':
        return EnvType.Dev;
      case 'pi':
        return EnvType.PI;
      case 'qa':
        return EnvType.QA;
      case 'qs':
        return EnvType.QS;
      case 'stage':
        return EnvType.StageUsgov;
      case 'usgov-stage':
        return EnvType.StageUsgov;
      case 'commandcentral':
        return EnvType.ProdUsgov;
      case 'usgov':
        return EnvType.ProdUsgov;
      default:
        return EnvType.Local;
    }
  }
  getStaticInstrumentationKey(): string {
    switch (this.type) {
      case EnvType.Dev:
        return '6e44fbcb-673e-414c-aae6-ce72e737a2f7';
      case EnvType.QA:
        return '1e09f757-9f17-4fba-a58b-7877675f27cf';
      default:
        return 'invalid';
    }
  }
}
