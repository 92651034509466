import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { IncidentModel } from '../model/IncidentModel';
import { UnitModel } from '../model/UnitModel';
import { EnvService } from './env.service';
import { TenantMappingService } from './tenant-mapping.service';
import { TokenService } from './token.service';
@Injectable({
  providedIn: 'root'
})
export class SyncService {
  private opt;
  private p1CustomerId;
  private ccAdminCustomerConfig: any;
  private tenantId;
  TenantIdLoader: Subscription;
  P1CustomerIdLoader: Subscription;
  constructor(private http: HttpClient, private env: EnvService, private tokenService: TokenService) {
                this.P1CustomerIdLoader = this.env.CCAdminCustomerConfiguration.subscribe(config => {
        this.ccAdminCustomerConfig = config;
        this.p1CustomerId = this.ccAdminCustomerConfig.customerId;
        this.opt = {headers: new HttpHeaders({'Content-Type': 'application/json', customerId: this.p1CustomerId})};
      });
                this.TenantIdLoader = this.tokenService.currTenant.subscribe(tenantId => {
        this.tenantId = tenantId;
      });
  }

  SendSiteNotification(siteNotificationType: string) {
    const SiteNotificationURL = this.env.getCadCloudBaseUrl() + 'sync/notification/' + siteNotificationType;
    return this.http.post(SiteNotificationURL, undefined, this.opt);
  }

  SyncByIncidentId(incidentId: string) {
    const SyncByIncidentIdURL = this.env.getCadCloudBaseUrl() + 'sync/incident/' + incidentId;
    return this.http.post(SyncByIncidentIdURL, undefined, this.opt);
  }

  SyncByUnitId(incidentId: string) {
    const SyncByUnitIdURL = this.env.getCadCloudBaseUrl() + 'sync/unit/' + incidentId;
    return this.http.post(SyncByUnitIdURL, undefined, this.opt);
  }

  DeltaASync(deltaSyncRequest: any) {
    const DeltaSyncURL = this.env.getCadCloudBaseUrl() + 'sync/deltaAsync';
    return this.http.post(DeltaSyncURL, deltaSyncRequest, this.opt);
  }

  TimeZeroASync() {
    const TimeZeroSyncRequestURL = this.env.getCadCloudBaseUrl() + 'sync/timeZeroAsync';
    return this.http.post(TimeZeroSyncRequestURL, undefined, this.opt);
  }

  GetIncidentsByTenantId() {
    const GetIncidentsByTenantIdRequestURL = this.env.getCadCloudBaseUrl() + 'data/tenant/' + this.tenantId + '/incidents';
    return this.http.get<Array<IncidentModel>>(GetIncidentsByTenantIdRequestURL,  this.opt);
  }

  GetUnitsByTenantId() {
    const GetIncidentsByTenantIdRequestURL = this.env.getCadCloudBaseUrl() + 'data/tenant/' + this.tenantId + '/units';
    return this.http.get<Array<UnitModel>>(GetIncidentsByTenantIdRequestURL, this.opt);
  }

  GetPendingAsyncTasks() {
  }

  CheckPendingAsyncTaskStatus(correlationId: string) {
    const CheckPendingAsyncTaskStatusRequestURL = this.env.getCadCloudBaseUrl() + 'status/asyncOperation/' + correlationId;
    return this.http.get(CheckPendingAsyncTaskStatusRequestURL, this.opt);
  }
}
