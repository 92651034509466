import { Injectable } from '@angular/core';
import { SBSettings, CadType } from '../model/commonDefinitions';
import { Subject, Observable, throwError, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';
import { EnvService } from './env.service';

const BLANK_SB_SETTINGS: SBSettings = {
  customerId: 'N/A',
  requestTopic: {
    name: 'N/A',
    cadType: 'N/A',
    subscriptionId: 'N/A'
  },
  responseTopic: {
    name: 'N/A'
  },
  whenUpdated: new Date(),
  customerType: 'N/A',
  cadEnvironment: 'N/A',
  clientIds: 'N/A',
  outOfSyncCheckFrequency: 0
};

@Injectable({
  providedIn: 'root'
})
export class AzureResourcesService {
  serviceBusSettingsUrl: string;

  constructor(private http: HttpClient, private env: EnvService) {
  }

  sbSettings = new Subject<SBSettings>();

  loadAzureResources(p1CustomerId: string): Observable<boolean> {
    console.log('loading azure resources');
    this.serviceBusSettingsUrl = this.env.getCadCloudBaseUrl() + 'settings/serviceBus';
    return this.getCompleteServiceBusSettingsForCustomer(p1CustomerId).pipe(
      map(s => {
        console.log('found azure resources');
        this.sbSettings.next(s);
        console.log(s);
        return true;
      }),
      catchError((e) => {
        console.log('settings not found', e);
        this.sbSettings.next(BLANK_SB_SETTINGS);
        return throwError(e);
    }));
  }

  getCompleteServiceBusSettingsForCustomer(p1CustomerId: string): Observable<SBSettings> {
    return this.http.get<SBSettings>(this.serviceBusSettingsUrl + '/' + p1CustomerId)
    .pipe(tap(_ => console.log('found settings: ', JSON.stringify(_))));
  }

  createServiceBus(
    p1CustomerId: string, cadTypeValue: CadType, customerTypeValue: string, cadEnvironmentValue: string, clientIdsValue: string):
  Observable<any> {
    console.log(cadTypeValue);
    console.log(customerTypeValue);
    console.log(clientIdsValue);
    const payload = {
      cadType: cadTypeValue,
      customerType: customerTypeValue,
      cadEnvironment: cadEnvironmentValue,
      clientIds: clientIdsValue
    };
    console.log(payload);
    return this.http.post(this.serviceBusSettingsUrl + '/' + p1CustomerId, payload)
      .pipe(
        catchError((err) => {
          console.log(err.status);
          if (err.status === 409) {
            console.log('returning empty');
            return of({});
          }
          return throwError(err);
        }
      ));
  }

  updateServiceBus(
    p1CustomerId: string, cadTypeValue: string, customerTypeValue: string, cadEnvironmentValue: string, clientIdsValue: string,
    outOfSyncCheckFrequencyValue: number)
    : Observable<any> {
    console.log(cadTypeValue);
    console.log(clientIdsValue);
    console.log(outOfSyncCheckFrequencyValue);
    const payload = {
      cadType: cadTypeValue,
      customerType: customerTypeValue,
      cadEnvironment: cadEnvironmentValue,
      clientIds: clientIdsValue,
      outOfSyncCheckFrequency: outOfSyncCheckFrequencyValue
    };
    console.log(payload);
    return this.http.put(this.serviceBusSettingsUrl + '/' + p1CustomerId, payload)
      .pipe(
        catchError((err) => {
          console.log(err.status);
          if (err.status === 409) {
            console.log('returning empty');
            return of({});
          }
          return throwError(err);
        }
        ));
  }
 updateCustomerSettings(
  p1CustomerId: string, cadTypeValue: string, customerTypeValue: string, cadEnvironmentValue: string,
  clientIdsValue: string, isRecreate: string, outOfSyncCheckFrequencyValue: number):
  Observable<any> {
    console.log(cadTypeValue);
    console.log(clientIdsValue);
    console.log(customerTypeValue);
    console.log(outOfSyncCheckFrequencyValue);
    const payload = {
      cadType: cadTypeValue,
      customerType: customerTypeValue,
      cadEnvironment: cadEnvironmentValue,
      clientIds: clientIdsValue,
      outOfSyncCheckFrequency: outOfSyncCheckFrequencyValue
    };
    console.log(payload);

    const headers = {headers: new HttpHeaders({'Content-Type': 'application/json', isRecreateSbResources: isRecreate})};
    return this.http.put(this.serviceBusSettingsUrl + '/' + p1CustomerId, payload, headers)
      .pipe(
        catchError((err) => {
          console.log(err.status);
          if (err.status === 409) {
            console.log('returning empty');
            return of({});
          }
          return throwError(err);
        }
        ));
  }
}
