import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from './env.service';
import { APIResponse } from '../model/commonDefinitions';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CcDriveOnboardService {
  private ccDriveOnboardUrl: string;
  private amsAttachmentFolderUrl: string;
  ccDriveOnboardResponse: Subject<number> = new ReplaySubject<number>(500);
  amsCreateFolderResponse: Subject<number> = new ReplaySubject<number>(500);
  constructor(private http: HttpClient, private env: EnvService) {}

  // Call Admin API to onboard ccDrive service
  onboardCcDrive(customerId: string) {
    this.ccDriveOnboardUrl = this.env.getCadCloudBaseUrl() + `settings/ccDrive/` + customerId + `/onboard`;
    return this.http.post<APIResponse>(this.ccDriveOnboardUrl, '').subscribe({
      next: response => {
        console.log(`ccDrive onboard for customer: ` + customerId + ` successful with correlation Id: ` +
          response.correlationId);
        this.ccDriveOnboardResponse.next(200);
      },
      error: err => {
        console.error(`ccDrive onboard failed with ${err}`);
        this.ccDriveOnboardResponse.next(err.status);
      }
    });
  }

  // Call Admin API to create AMS folders for incident attachment permissions
  createAmsFolder(customerId: string, tenantId: string) {
    this.amsAttachmentFolderUrl = this.env.getCadCloudBaseUrl() + `settings/ccDrive/` + customerId + `/attachment/amsFolder`;
    const httpHeaders: HttpHeaders = new HttpHeaders({tenantId});
    return this.http.post<APIResponse>(this.amsAttachmentFolderUrl, ``, { headers: httpHeaders }).subscribe({
      next: response => {
        console.log(`Created AMS Attachment Folder for customer: ` + customerId + ` tenantId: ` + tenantId +
          ` with correlation Id: ` + response.correlationId);
        this.amsCreateFolderResponse.next(200);
      },
      error: err => {
        console.error(`Create AMS Attachment Folder Failed with ${err}`);
        this.amsCreateFolderResponse.next(err.status);
      }
    });
  }
}
