import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { EnvService } from './env.service';
import { TenantMapping, CadType } from '../model/commonDefinitions';
import { ReplaySubject, Subject, throwError, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TenantMappingService {
  private tenantMappingsUrl: string;
  tenantMapping: Subject<TenantMapping> = new ReplaySubject<TenantMapping>(1);
  cadTypeSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  // root Agency （P1customerID == tenantID) would be regarded as Admin
  constructor(private http: HttpClient, private env: EnvService) {
  }

  loadTenantMapping(tenantId: string) {
    this.tenantMappingsUrl = this.env.getCadCloudBaseUrl() + 'mappings/tenant';
    return this.http.get<TenantMapping>(this.tenantMappingsUrl + '/' + tenantId).subscribe(
      m => {
        console.log(`found tenant mapping: ` + JSON.stringify(m));
        if (m.cadType === null || (m.cadType as any) === '' ) {
          m.cadType = CadType.PremierOne; // TODO: remove this at some point..
        }
        this.cadTypeSubject.next(m.cadType);
        this.tenantMapping.next(m);
      },
      e => {
        console.log(`Could not get the tenant mapping ${e}`);
        this.tenantMapping.error(e);
      }
    );
  }
}
