import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SettingsComponent } from './settings/settings.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { UserMappingComponent } from './user-mapping/user-mapping.component';
import { AzureSettingsComponent } from './azure-settings/azure-settings.component';
import { MainViewComponent } from './main-view/main-view.component';
import { httpInterceptorProviders } from './http-interceptors';
import { TenantMappingLoaderComponent } from './tenant-mapping-loader/tenant-mapping-loader.component';
import { IamAuthGrantConfigToken, UserAuthenticationModule } from '@msi/commandcentral-user-authentication';
import { getAppSpecificIamConfig } from './config/app.config';
import { CommandCentralHeaderComponentsModule } from '@msi/commandcentral-common-header';
import { MaterialCollectionModule } from './material-collection/material-collection.module';
import { DeltaSyncDialogComponent } from './widget-dialogs/delta-sync-dialog/delta-sync-dialog.component';
import { IncidentSyncDialogComponent } from './widget-dialogs/incident-sync-dialog/incident-sync-dialog.component';
import { UnitSyncDialogComponent } from './widget-dialogs/unit-sync-dialog/unit-sync-dialog.component';
import { WidgetSetComponent } from './widget-set/widget-set.component';
import { SiteNotificationDialogComponent } from './widget-dialogs/site-notification-dialog/site-notification-dialog.component';
import { PendingAsyncTaskMonitorComponent } from './pending-async-task-monitor/pending-async-task-monitor.component';
import { TimeZeroSyncDialogComponent } from './widget-dialogs/time-zero-sync-dialog/time-zero-sync-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    SettingsComponent,
    ToolbarComponent,
    UserMappingComponent,
    AzureSettingsComponent,
    MainViewComponent,
    TenantMappingLoaderComponent,
    DeltaSyncDialogComponent,
    IncidentSyncDialogComponent,
    UnitSyncDialogComponent,
    WidgetSetComponent,
    SiteNotificationDialogComponent,
    PendingAsyncTaskMonitorComponent,
    TimeZeroSyncDialogComponent,
  ],
  exports: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    CommandCentralHeaderComponentsModule,
    UserAuthenticationModule,
    ReactiveFormsModule,
    MaterialCollectionModule
  ],
  providers:
  [
    httpInterceptorProviders,
    {
      provide: IamAuthGrantConfigToken,
      useFactory: getAppSpecificIamConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
