export interface CadAgency {
    /**
     * Agency Key.
     */
    key: string;
    /**
     * Agency ID/Alias.
     */
    alias: string;
    /**
     * Customer ID / Host of the on prem CAD system.
     */
    customerId: string;
    /**
     * Agency Type.
     */
    agencyType?: string;
    /**
     * Agency description.
     */
    description?: string;
    /**
     * Agency display value.
     */
    displayValue?: string;

    cadType: string;
}

// export interface CadCustomer {
//     id: number;
//     customer: string;
// }

export type CadCustomer = string;
export interface Agency {
    code: string;
    displayValue: string;
    id: string;
}

export interface AgencyOneRMS {
    codes: [Agency];
}

export class TenantMapping {
    tenantId: string;
    customerId: string;
    cadAgencyKey: string;
    cadAgencyAlias: string;
    cadType: CadType;
}

export class CCUser {
    key: string;
    id: string;
    uuid: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    officerId?: string;
}

export class SBSettings {
    customerId: string;
    requestTopic: {
        name: string,
        cadType: string,
        subscriptionId: string
    };
    responseTopic: {
        name: string
    };
  whenUpdated: Date;
  customerType: string;
  cadEnvironment: string;
  clientIds: string;
  outOfSyncCheckFrequency: number;
}

export class CADUser {
    cadUserKey: string;
    cadUserAgencyKey: string;
    cadUserName: string;
    customerId: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    CommonName: string;
    DisplayValue: string;
}

export enum CadType {
    PremierOne = 'PremierOne',
    Flex = 'Flex',
    ccCad = 'ccCad',
    P1CloudCad = 'P1CloudCad'
}

export class UserMapping {
    userKey: string;
    userId: string;
    uuid: string;
    tenantId: string;
    customerId: string;
    cadUserKey: string;
    cadUserAgencyKey: string;
    cadUserName: string;
    whenUpdated: Date;
  }

export class CCAgency {
    key: string;
    id: string;
    description: string;
    customerName: string;
    customerId: string;
    cadAgencyAlias: string;
    environment: string;
    cadAgencyTypeAlias: string;
    syncMode: string;
    cadAgencyDefaultAreaAlias: string;
    isGetCadRecordsNumber: boolean;
    isEnableIncidentAttachment: boolean;
}

export class APIResponse {
    correlationId: string;
}

export class KeyValuePair {
    key: string;
    value: string;
}

export class APIError {
    correlationId: string;
    errorCode: string;
    domain: string;
    reason: string;
    message: string;
    extendedHelp: string;
    fieldPath: string;
    parsedResources: [KeyValuePair];
}
