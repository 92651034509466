import { Component, OnInit, OnDestroy } from '@angular/core';
import { CadType, SBSettings } from '../model/commonDefinitions';
import { AzureResourcesService } from '../services/azure-resources.service';
import { Subscription } from 'rxjs';
import { TenantMappingService } from '../services/tenant-mapping.service';
import { EnvService } from '../services/env.service';
class ActionButton {
  name: string;
  callback: () => void;
  isActive: boolean;
}

class ErrorMessageDetails {
  title: string;
  description: string;
  actions?: ActionButton[];
}

@Component({
  selector: 'app-azure-settings',
  templateUrl: './azure-settings.component.html',
  styleUrls: ['./azure-settings.component.scss']
})
export class AzureSettingsComponent implements OnInit, OnDestroy {
  sbSettings: SBSettings;
  sbSubscription: Subscription;
  customerTypes = [
    { value: 'All', viewValue: 'All' },
    { value: 'PassThru', viewValue: 'PassThru' },
    { value: 'ClientOnly', viewValue: 'ClientOnly' },
    { value: 'Disabled', viewValue: 'Disabled' }
  ];
  selectedCustomerType: string;
  environmentInputText: string;
  clientIdsText: string;
  editState: string;
  editClientIdsState: string;
  error: ErrorMessageDetails;
  editOutOfSyncCheckFrequencyState: string;
  customerTypeDropdownDisabled = true;
  warningButtonDisabled = true;
  environmentInputTextDisabled = true;
  clientIdsTextDisabled = true;
  outOfSyncCheckFrequencyTextDisabled = true;
  customerType: string;
  savingData = false;
  isCustomerAdmin = false;
  isCustomerAdminLoader: Subscription;
  constructor(private azureResources: AzureResourcesService, private envService: EnvService) {
   }

  ngOnInit() {
    this.isCustomerAdminLoader = this.envService.isCustomerAdmin.subscribe(isAdmin => this.isCustomerAdmin = isAdmin);
    this.sbSubscription = this.azureResources.sbSettings.subscribe(s => this.sbSettings = s);
    console.log('Edit State on initialize:-' + this.editState);
    this.editState = 'View';
    this.editClientIdsState = 'ViewClientId';
    this.editOutOfSyncCheckFrequencyState = 'ViewOutOfSyncCheckFrequency';
    this.customerTypeDropdownDisabled = true;
    this.environmentInputTextDisabled = true;
    this.clientIdsTextDisabled = true;
    this.warningButtonDisabled = true;
    this.outOfSyncCheckFrequencyTextDisabled = true;
    this.savingData = false;
  }

  ngOnDestroy() {
    if (this.sbSubscription !== undefined) {
      this.sbSubscription.unsubscribe();
    }
    if (this.isCustomerAdminLoader !== undefined) {
      this.isCustomerAdminLoader.unsubscribe();
    }
  }
  public updateResources(): void {
    this.savingData = true;
    this.editState = 'View';
    this.customerTypeDropdownDisabled = true;
    this.environmentInputTextDisabled = true;
    this.clientIdsTextDisabled = true;
    this.warningButtonDisabled = true;
    this.outOfSyncCheckFrequencyTextDisabled = true;
    this.azureResources.updateServiceBus(
      this.sbSettings.customerId, this.sbSettings.requestTopic.cadType, this.sbSettings.customerType,
      this.sbSettings.cadEnvironment, this.sbSettings.clientIds, this.sbSettings.outOfSyncCheckFrequency).subscribe(
      () => {
        console.log('update resource succeeded');
        this.checkAzureResources(this.sbSettings.customerId, this.sbSettings.requestTopic.cadType, this.sbSettings.clientIds);
        this.savingData = false;
      },
      (err) => {
        console.log('update resource failed');
        this.error.actions.map((a) => {
          a.isActive = true;
          this.savingData = false;
        });
      }
    );
  }

  public updateCustomerSettings(): void {
    this.savingData = true;
    this.editState = 'View';
    this.editClientIdsState = 'ViewClientId';
    this.editOutOfSyncCheckFrequencyState = 'ViewOutOfSyncCheckFrequency';
    this.customerTypeDropdownDisabled = true;
    this.environmentInputTextDisabled = true;
    this.clientIdsTextDisabled = true;
    this.warningButtonDisabled = true;
    this.outOfSyncCheckFrequencyTextDisabled = true;
    this.azureResources.updateCustomerSettings(
      this.sbSettings.customerId, this.sbSettings.requestTopic.cadType, this.sbSettings.customerType,
      this.sbSettings.cadEnvironment, this.sbSettings.clientIds, 'false', this.sbSettings.outOfSyncCheckFrequency).subscribe(
      () => {
        console.log('update resource succeeded');
        this.checkAzureResources(this.sbSettings.customerId, this.sbSettings.requestTopic.cadType, this.sbSettings.clientIds);
        this.savingData = false;
      },
      (err) => {
        console.log('update resource failed');
        this.error.actions.map((a) => {
          a.isActive = true;
          this.savingData = false;
        });
      }
    );
  }

  private checkAzureResources(customerId: string, cadType: string, clientIds: string) {
    this.azureResources.loadAzureResources(customerId).subscribe(
      () => {
        this.error = null;
      },
      (error) => {
        console.log('errorMessage updated', error);
        if (error.status === 404) {
          this.error = {
            title: `CadCloud API resources for customer '${customerId}' not found.`,
            description: 'Would you like to create the resources?',
            actions: [
              {
                name: 'Create',
                callback: this.createResources.bind(this, customerId, cadType, clientIds),
                isActive: true,
              },
            ],
          };
        }
      }
    );
  }

  public createResources(customerId: string, cadType: CadType, clientIds: string): void {
    this.error.actions.map((a) => {
      a.isActive = false;
    });
    this.azureResources.createServiceBus(customerId, cadType, this.selectedCustomerType, this.sbSettings.cadEnvironment,
      this.sbSettings.clientIds).subscribe(
      () => {
        console.log('create resource succeeded');
        this.checkAzureResources(customerId, cadType, clientIds);
      },
      (err) => {
        console.log('create resource failed');
        this.error.actions.map((a) => {
          a.isActive = true;
        });
      }
    );
  }

  public editResources(): void {
    this.editState = 'Edit';
    this.customerTypeDropdownDisabled = false;
    this.environmentInputTextDisabled = false;
    this.warningButtonDisabled = false;
    console.log('Edit state:' + this.editState + 'Customer Type Dropdown Disabled:' + this.customerTypeDropdownDisabled +
    + 'Warning Button Disabled:' + this.warningButtonDisabled);
  }

  public cancelResources(): void {
    this.editState = 'View';
    this.customerTypeDropdownDisabled = true;
    this.environmentInputTextDisabled = true;
    this.warningButtonDisabled = true;
    this.selectedCustomerType = this.sbSettings.customerType;
    this.environmentInputText = this.sbSettings.cadEnvironment;
    this.checkAzureResources(this.sbSettings.customerId, this.sbSettings.requestTopic.cadType, this.sbSettings.clientIds);
    console.log('Edit state after cancel:-' + this.editState + 'SelectedCustomerType:' + this.selectedCustomerType);
  }

  public editClientIds(): void {
    this.editClientIdsState = 'EditClientId';
    this.clientIdsTextDisabled = false;
    console.log('Edit client ids state: ' + 'Client Id text Disabled:' + this.clientIdsTextDisabled);
  }
  public cancelClientIds(): void {
    this.editClientIdsState = 'ViewClientId';
    this.clientIdsTextDisabled = true;
    console.log('View client ids state: ' + 'Client Id text Disabled:' + this.clientIdsTextDisabled);
    this.checkAzureResources(this.sbSettings.customerId, this.sbSettings.requestTopic.cadType, this.sbSettings.clientIds);
  }

  public editOutOfSyncCheckFrequency(): void {
    this.editOutOfSyncCheckFrequencyState = 'EditOutOfSyncCheckFrequency';
    this.outOfSyncCheckFrequencyTextDisabled = false;
    console.log(
      'Out of sync check frequency state: ' + 'Out of sync check frequency Disabled:' + this.outOfSyncCheckFrequencyTextDisabled
    );
    alert(`Alert: The acceptable range is 5 to 60 minutes. It is recommended to set the value at 15 minutes.`);
  }
  public cancelOutOfSyncCheckFrequency(): void {
    this.editOutOfSyncCheckFrequencyState = 'ViewOutOfSyncCheckFrequency';
    this.outOfSyncCheckFrequencyTextDisabled = true;
    console.log(
      'View Out of sync check frequency state: ' + 'Out of sync check frequency text Disabled:' + this.outOfSyncCheckFrequencyTextDisabled
    );
    this.checkAzureResources(this.sbSettings.customerId, this.sbSettings.requestTopic.cadType, this.sbSettings.clientIds);
  }
}
