import { Injectable, Type } from '@angular/core';
import { EnvTypeService } from './envType.service';
import { EnvType } from './envType.service';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { HeaderTokenService } from '@msi/commandcentral-user-authentication';
import { take, switchMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
const INVALID_TOKEN = 'invalid';
@Injectable({
  providedIn: 'root',
})
export class TokenService {
  title = 'CAD-AdminUI';
  customerId = sessionStorage.getItem('customer');
  agencyId = sessionStorage.getItem('agency');
  tenantId = sessionStorage.getItem('tenant');
  user = sessionStorage.getItem('user');
  token;
  clientId;
  roleSystemName;
  groupSystemName;
  private Customer = new BehaviorSubject<string>(this.customerId);
  private Agency = new BehaviorSubject<string>(this.agencyId);
  private Tenant = new BehaviorSubject<string>(this.tenantId);
  private TOKEN_KEY = 'JWT_TOKEN';
  private userToken: string;
  private env;
  private ready = new BehaviorSubject<boolean>(false);
  private getMeurl;
  private SuperAdminIdentity = 'motorola-solutions';
  // for sharing agency infomation between different components
  currentAgency = this.Agency.asObservable();
  currCustomer = this.Customer.asObservable();
  currTenant = this.Tenant.asObservable();
  isReady = this.ready.asObservable();
  GetMeDone = false;
  constructor(
    private headerTokenService: HeaderTokenService,
    private envService: EnvTypeService,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    console.log(`token.service::constructor - Starting`);

    const envPrefixes = new Map()
      .set(EnvType.Local, 'pi.')
      .set(EnvType.Dev, 'dev.')
      .set(EnvType.QA, 'qa.')
      .set(EnvType.Stage, 'pi.')
      .set(EnvType.StageUsgov, 'pi.')
      .set(EnvType.Prod, '')
      .set(EnvType.ProdUsgov, 'usgov.')
      .set(EnvType.PI, 'pi.');

    this.env = envPrefixes.get(this.envService.type);
    this.getMeurl = `https://admin-api.${this.env}commandcentral.com/api/admin-v2/me`;
    console.log(`token.service::constructor - Ending: The current environment inside the token service ${this.env}`);
  }

  init() {
    console.log(`token.service::Before init()`);
    if (this.userToken) {
      console.log(`init service subsequent call`);
      this.ready.next(true);
    } else {
      // fallback, in case parent does not respond
      this.route.queryParams.subscribe((params) => {
        this.customerId = params.customerId;
        this.roleSystemName = params.roleSystemName;
        this.groupSystemName = params.groupSystemName;
        this.agencyId = params.agencyId;
        this.tenantId = params.customerId;
        console.log(`token.service::.route.queryParams customerId: ${this.customerId}`);
      });
      console.log(`token.service::Before headerTokeService`);
      this.headerTokenService.tokenReady.pipe(take(1)).subscribe((isTokenReady) => {
        if (isTokenReady) {
          console.log(`token.service::User isTokenReady: ${isTokenReady}`);
          const texttoken = this.headerTokenService.getTokenSync();
          this.userToken = texttoken;
          this.setToken(this.userToken);
          const token = this.encodeToken(texttoken);
          console.log(token);
          this.user = token.sub;
          sessionStorage.setItem('user', this.user);
          this.clientId = token.client_id;
          if (token.customer && token.agencyId && token.customer !== this.SuperAdminIdentity
            && token.agencyId !== this.SuperAdminIdentity) {
            this.customerId = token.customer;
            this.agencyId = token.agency;
            this.tenantId = token.customer;
          }
          console.log(`token.service::User Token: ${this.userToken}`);
          if (this.envService.type === EnvType.Local) {
            this.http
            .get<any>(this.getMeurl)
            .pipe(take(1))
            .subscribe(
              (data) => {
                if (this.GetMeDone === false) {
                  this.GetMeDone = true;
                  if (data.impersonatingUser !== undefined) {
                    this.agencyId = data._links.agency.agencyId;
                    this.customerId = data._links.customer.customerId;
                    this.tenantId = data._links.customer.customerId;
                  }
                  this.readyTrigger();
                }
              },
              (err) => {
                this.ready.next(false);
              }
            );
          } else {
            this.readyTrigger();
          }
        }
      });
      console.log(`token.service::After headerTokeService`);
    }
    console.log(`token.service::End init()`);
  }

  retrieveToken() {
    return this.isValidToken() ? this.userToken : this.getStaticToken();
  }

  private getStaticToken() {
    const storedToken = sessionStorage.getItem(this.TOKEN_KEY);
    return storedToken ? storedToken : INVALID_TOKEN;
  }

  private isValidToken() {
    return this.userToken && this.userToken !== INVALID_TOKEN;
  }

  private encodeToken(token: string) {
    const payloadBase64 = token.split('.')[1];
    const payloadStr = atob(payloadBase64);
    return JSON.parse(payloadStr);
  }

  private setToken(data) {
    sessionStorage.setItem(this.TOKEN_KEY, data);
  }

  private readyTrigger() {
    sessionStorage.setItem('customer', this.customerId);
    sessionStorage.setItem('agency', this.agencyId);
    sessionStorage.setItem('tenant', this.tenantId);
    this.Agency.next(this.agencyId);
    this.Customer.next(this.customerId);
    this.Tenant.next(this.tenantId);
    console.log('ccAdmin CustomerId: ' + this.agencyId);
    console.log('ccAdmin AgencyId: ' + this.customerId);
    console.log('ccTenant = ccAdmin CustomerId = ccAdmin AgencyId = ' + this.tenantId);
    this.ready.next(true);
  }
}
