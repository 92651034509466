import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AsyncTask } from '../model/AsyncTask';
import { SyncStatus } from '../model/SyncStatus';

@Component({
  selector: 'app-pending-async-task-monitor',
  templateUrl: './pending-async-task-monitor.component.html',
  styleUrls: ['./pending-async-task-monitor.component.scss']
})
export class PendingAsyncTaskMonitorComponent implements OnInit, OnDestroy {
  @Input() pendingAsyncTasks: Map<string, AsyncTask>;
  correlationIds: Array<string>;
  constructor() { }
  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.correlationIds = Array.from(this.pendingAsyncTasks.keys());
    console.log(this.correlationIds);
  }

  checkStatusOfAsyncTask(correlationId: string) {
    const asyncTask = this.pendingAsyncTasks.get(correlationId);
    asyncTask.finishedCount++;
    if (asyncTask.finishedCount >= asyncTask.totalCount) {
      asyncTask.status = SyncStatus.Finished;
    }
    asyncTask.ratio = asyncTask.finishedCount / asyncTask.totalCount * 100;
    this.pendingAsyncTasks.set(correlationId, asyncTask);
  }

}
