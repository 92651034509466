import { Component, Inject, OnInit } from '@angular/core';
import { TokenService } from './services/token.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'cloudadmin-ui-app';
  isInitialized = false;

  constructor(private tokenService: TokenService) {
    console.log('AppComponent::constructor - Starting');
    if (!this.isInitialized) {
      this.tokenService.isReady.subscribe((isReady) => {
        if (isReady) {
          this.isInitialized = true;
          console.log('AppComponent::constructor - Init done');
        }
      });
    }
    this.tokenService.init();
    console.log('AppComponent::constructor - Ending');
  }
}
