import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthenticationInterceptor } from './authentication-interceptor';
import { ErrorHandlingInterceptor } from './error-handling-interceptor';
import { PostInterceptor } from './post-interceptor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: PostInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlingInterceptor, multi: true },
];
