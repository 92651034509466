import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { SiteNotification } from '../model/SiteNotification';
import { DeltaSyncDialogComponent } from '../widget-dialogs/delta-sync-dialog/delta-sync-dialog.component';
import { IncidentSyncDialogComponent } from '../widget-dialogs/incident-sync-dialog/incident-sync-dialog.component';
import { SiteNotificationDialogComponent } from '../widget-dialogs/site-notification-dialog/site-notification-dialog.component';
import { UnitSyncDialogComponent } from '../widget-dialogs/unit-sync-dialog/unit-sync-dialog.component';
import { TimeZeroSyncDialogComponent } from '../widget-dialogs/time-zero-sync-dialog/time-zero-sync-dialog.component';
import { EnvService } from '../services/env.service';
import { TenantMappingService } from '../services/tenant-mapping.service';
@Component({
  selector: 'app-widget-set',
  templateUrl: './widget-set.component.html',
  styleUrls: ['./widget-set.component.scss']
})
export class WidgetSetComponent implements OnInit, OnDestroy {
  constructor(public dialog: MatDialog, private envService: EnvService, private tenantMappingService: TenantMappingService) { }
  siteDownNotification = SiteNotification.SiteDown;
  siteUpNotification = SiteNotification.SiteUp;
  isCustomerAdmin = false;
  isCustomerAdminLoader: Subscription;
  cadTypeLoader: Subscription;
  cadType: string;
  isOnPremCad = false;
  ngOnInit(): void {
    this.isCustomerAdminLoader = this.envService.isCustomerAdmin.subscribe(isAdmin => {
        this.isCustomerAdmin = isAdmin;
      });
    this.cadTypeLoader = this.tenantMappingService.cadTypeSubject.subscribe(cadType => {
        this.cadType = cadType;
      });
    // Check if it is a on prem CAD - no sync menu enabled if it is an on prem customer
    this.isOnPremCad = this.cadType.toUpperCase() === 'PREMIERONE' || this.cadType.toUpperCase() === 'FLEX';
  }
  ngOnDestroy(): void {
    if (this.isCustomerAdminLoader !== undefined) {
      this.isCustomerAdminLoader.unsubscribe();
    }
    if (this.cadTypeLoader !== undefined) {
      this.cadTypeLoader.unsubscribe();
    }
  }
  // delta sync dialog
  openDeltaSyncDialog(): void {
    const dialogRef = this.dialog.open(DeltaSyncDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  // incident sync dialog
  openIncidentSyncDialog(): void {
    const dialogRef = this.dialog.open(IncidentSyncDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  // unit sync dialog
  openUnitSyncDialog(): void {
    const dialogRef = this.dialog.open(UnitSyncDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  // site notification dialog
  openSiteNotificationDialog(siteNotificationType: SiteNotification): void {
    const dialogRef = this.dialog.open(SiteNotificationDialogComponent, {data: siteNotificationType});
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  // time zero sync dialog
  openTimeZeroSyncDialog() {
    const dialogRef = this.dialog.open(TimeZeroSyncDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
