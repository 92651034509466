import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

export class PostInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const method = req.method.toLowerCase();
        if (!req.url.includes('idm.pi.commandcentral.com') &&
        !req.url.includes('idmmaster.imw.motorolasolutions.com') &&
        !req.url.includes('idm.imw.motorolasolutions.com')) {
          if (method === 'post' || method === 'put') {
            req = req.clone({
                headers: req.headers.set('Content-Type', 'application/json')
              });
          }
        }
        return next.handle(req);
    }
}
