import { Injectable } from '@angular/core';
import { UserMapping, TenantMapping } from '../model/commonDefinitions';
import { CsvColumns } from '../model/exported-data-model';
import { UserMappingService } from './user-mapping.service';
import { TenantMappingService } from './tenant-mapping.service';

@Injectable({
  providedIn: 'root'
})
export class ExporterService {

  constructor(private userMappingService: UserMappingService, private tenantMappingService: TenantMappingService) { }

  private verifyHeader(header: string) {
    // TODO: verify all columns exist and are in proper order
    return true;
  }

  private processCsv(entries: string[]): UserMapping[] {
    const out = entries
      .filter(line => line.trim())
      .map(a => this.extractData(a));
    console.log(out);
    return out;
  }

  private extractData(data: string): UserMapping {
    const fields = data.split(',');
    const mapping: UserMapping = new UserMapping();
    CsvColumns.map((col, i) => mapping[col.importField ? col.importField : col.field] = fields[i]);
    return mapping;
  }

  getMappingsFromCsv(fileContent): UserMapping[] {
    const csv = fileContent.split('\n');
    const header = csv[0];
    const lines = csv.slice(1);
    this.verifyHeader(header);
    return this.processCsv(lines);
  }

  private createExporterEntry(userMapping: UserMapping, tenantMapping: TenantMapping): string {
    let row = '';
    row += CsvColumns.reduce((a, col) => {
      return (a !== '' ? a + ',' : '') + ((col.source === 'tenant' ? tenantMapping[col.field] : userMapping[col.field]) || '' );
    }, row);
    row += '\n';
    console.log('row is now:', row);
    return row;
  }

  exportToFile(mappings: UserMapping[], tenantMapping: TenantMapping) {
    console.log(CsvColumns.length);
    let blob = CsvColumns.reduce((a, col) => {
      return (a !== '' ? a + ',' : '') + col.name;
    }, '');
    blob += '\n';
    blob = mappings.reduce((a, mapping) => {
      return a + this.createExporterEntry(mapping, tenantMapping);
    }, blob);
    console.log(blob);
    this.download(`${tenantMapping.tenantId}.csv`, blob);
  }

  private download(filename, text) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  exportToCsv() {
    this.tenantMappingService.tenantMapping.subscribe(m => {
      this.exportToFile(this.userMappingService.displayableMappings.map(a => a.mapping), m);
    });
  }

  importMappings(fileName) {
    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const importedMappings = this.getMappingsFromCsv(e.target.result);
        this.tenantMappingService.tenantMapping.subscribe(m => {
          this.userMappingService.loadImportedMappings(importedMappings, m);
        });
      };
      reader.readAsText(fileName);
    }
  }

}
