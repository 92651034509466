import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from '../services/token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.tokenService.retrieveToken();
    if (!token.includes('Bearer ')) {
      token = 'Bearer ' + token;
    }
    if (
      !req.url.includes('idm.pi.commandcentral.com') &&
      !req.url.includes('idmmaster.imw.motorolasolutions.com') &&
      !req.url.includes('idm.imw.motorolasolutions.com')
    ) {
      req = req.clone({
        headers: req.headers.set('Authorization', token),
      });
    }
    return next.handle(req);
  }

  constructor(private tokenService: TokenService) {}
}
