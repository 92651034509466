import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ActivatedRouteSnapshot, ResolveEnd, Router,
  NavigationEnd, NavigationCancel, NavigationError, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { EnvTypeService } from '../services/envType.service';

@Injectable({
  providedIn: 'root'
})
export class AppInsightService {

  private appInsights: ApplicationInsights;

  constructor(private router: Router, env: EnvTypeService) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: env.getStaticInstrumentationKey(),
        appId: 'CadCloudAdminUi'
      }
    });
    this.appInsights.loadAppInsights();
    if (this.router) {
      this.router.events
        .pipe(filter(event => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
          const activatedComponent = this.getActivatedComponent(event.state.root);
          if (activatedComponent) {
            this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
          }
      });
      this.router.events
        .pipe(filter(event => event instanceof NavigationStart))
        .subscribe((event: NavigationStart) => {
          this.appInsights.startTrackPage(event.url);
        });

      this.router.events
        .pipe(
          filter(
            event =>
              event instanceof NavigationEnd ||
              event instanceof NavigationCancel ||
              event instanceof NavigationError
          )
        )
        .subscribe((event: NavigationEnd) => {
          this.appInsights.stopTrackPage(event.url);
        });
    }
  }

  logPageView(name?: string, uri?: string): void {
    this.appInsights.trackPageView({ name, uri });
  }

  logEvent(event: string): void {
    this.appInsights.trackEvent({name: event});
  }

  logError(type: string, event: string): void {
    this.appInsights.trackException({error: {name: type, message: event}});
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
