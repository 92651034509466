import { Injectable } from '@angular/core';
import { CadUserState } from '../model/CadUserState';
import { ApiService } from './api.service';
import { ManagedCadUser } from '../model/ManagedCadUser';
import { Subject, Observable, ReplaySubject, BehaviorSubject } from 'rxjs';
import { EditableCCUser } from '../model/EditableCCUser';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class CadUsersService {
  private iInitialized: Subject<boolean> = new ReplaySubject<boolean>(1);
  private iCadUsers: ManagedCadUser[];
  private observedCadUsers: Subject<ManagedCadUser> = new ReplaySubject<ManagedCadUser>(1);

  constructor(private envService: EnvService, private api: ApiService) {
    const self = this;
    envService.isTenantUrlLoaded((result) => {
      if (result) {
        self.loadCadUsers();
      }
    });
  }

  get cadUsers$(): Observable<ManagedCadUser> {
    return this.observedCadUsers;
  }

  get initialized$(): Observable<boolean> {
    return this.iInitialized;
  }

  getCadUsers(): ManagedCadUser[] {
    return this.iCadUsers;
  }

  private loadCadUsers() {
    const nullCadUser: ManagedCadUser = {
      cadUserKey: null,
      cadUserAgencyKey: null,
      cadUserName: null,
      lastName: null,
      customerId: null,
      CommonName: null,
      DisplayValue: null,
      state: CadUserState.Available
    };

    this.api.getCadUsers().subscribe(u => {
        this.iCadUsers = u.map(_ => Object.assign(_, {state: CadUserState.Available}));
        this.iCadUsers.push(nullCadUser);
        this.iCadUsers.sort((u1: ManagedCadUser, u2: ManagedCadUser) => (u1.lastName > u2.lastName) ? 1 : -1);
        console.log('about to emit..');
        this.iInitialized.next(true);
        this.iInitialized.complete();
      }
    );
  }

  filterCadUsers(query, row: EditableCCUser) {
    query = query.toLowerCase();
    console.log(query, row);
    row.selectedCadUsers = this.iCadUsers.filter(a => {
      let stringToMatch = '';
      a.cadUserName ? stringToMatch += a.cadUserName : stringToMatch = stringToMatch;
      a.lastName ? stringToMatch += a.lastName : stringToMatch = stringToMatch;
      return stringToMatch.toLowerCase().indexOf(query) > -1;
    });
  }

  disableCadUser(cadUserKey: string) {
    console.log('disabling user ', cadUserKey);
    const cadUser = this.getUserByKey(cadUserKey);
    if (cadUser != null) {
      cadUser.state = CadUserState.Unavailable;
      this.observedCadUsers.next(Object.assign({}, cadUser));
    }
  }

  enableCadUser(cadUserKey: string) {
    console.log('enabling user ', cadUserKey);
    const cadUser = this.getUserByKey(cadUserKey);
    if (cadUser != null) {
      cadUser.state = CadUserState.Available;
      this.observedCadUsers.next(Object.assign({}, cadUser));
    }
  }

  blockCadUser(cadUserKey: string) {
    console.log('blocking user ', cadUserKey);
    const cadUser = this.getUserByKey(cadUserKey);
    if (cadUser != null) {
      cadUser.state = CadUserState.Blocked;
      this.observedCadUsers.next(Object.assign({}, cadUser));
    }
  }

  releaseBlockCadUser(cadUserKey: string, finalState: CadUserState) {
    const cadUser = this.getUserByKey(cadUserKey);
    if (cadUser != null && cadUser.state === CadUserState.Blocked) {
      console.log('releasing block on user ', cadUserKey, 'state: ', finalState);
      cadUser.state = finalState;
      this.observedCadUsers.next(Object.assign({}, cadUser));
    }
  }

  getUserByKey(cadUserKey: string): ManagedCadUser {
    return this.iCadUsers.find((a) => a.cadUserKey === cadUserKey);
  }
}
