import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SyncService } from '../../services/sync.service';
import { SyncStatus } from '../../model/SyncStatus';
@Component({
  selector: 'app-time-zero-sync-dialog',
  templateUrl: './time-zero-sync-dialog.component.html',
  styleUrls: ['./time-zero-sync-dialog.component.scss']
})
export class TimeZeroSyncDialogComponent implements OnInit {
  status: SyncStatus;
  doubleConfirmFlag = false;
  constructor(public dialogRef: MatDialogRef<TimeZeroSyncDialogComponent>,
              private snackBar: MatSnackBar, private syncService: SyncService) { }

  ngOnInit(): void {
    this.status = SyncStatus.Initial;
  }
  onNoClick(): void {
    this.snackBar.dismiss();
    this.dialogRef.close();
  }

  startSync(): void {
    this.doubleConfirmFlag = false;
    this.status = SyncStatus.Progressing;
    this.syncService.TimeZeroASync().toPromise().then(() => {
      this.status = SyncStatus.Finished;
      this.snackBar.open('Synchronized Successfully!!!', 'Close',
      { duration: 8000, panelClass: ['successSnackBarColor']});
    }, err => {
      this.status = SyncStatus.Initial;
      let errMsg = '';
      if (err.status === 400) {
        errMsg = err.error;
      }
      this.snackBar.open('Failed to synchronize data. ' + errMsg, 'Close',
      { duration: 8000, panelClass: ['errorSnackBarColor']});
    });
  }
  openDoubleConfirmWindow(): void {
    this.doubleConfirmFlag = true;
  }
  closeDoubleConfirmWindow(): void {
    this.dialogRef.close();
  }
}
