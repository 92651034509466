import { Injectable } from '@angular/core';
import { Subject, forkJoin } from 'rxjs';
import { CadCustomer, CCUser, CADUser, UserMapping, TenantMapping } from '../model/commonDefinitions';
import { CadUserState } from '../model/CadUserState';
import { EditableCCUser } from '../model/EditableCCUser';
import { RowState } from '../model/RowState';
import { AppInsightService } from '../monitoring/app-insight.service';
import { ApiService } from './api.service';
import { CadUsersService } from './cad-users.service';
import { EnvService } from './env.service';

export interface MockCadCustomer {
  id: number;
  customer: CadCustomer;
}

@Injectable({
  providedIn: 'root'
})

export class UserMappingService {
  displayableMappings: EditableCCUser[];
  initialized$: Subject<boolean> = new Subject<boolean>();
  saveAllDisabled = true;

  private userMappings: UserMapping[];
  private ccUsers: CCUser[];

  constructor(private envService: EnvService,
              private api: ApiService,
              private cadUserService: CadUsersService,
              private monService: AppInsightService) {
    const self = this;
    envService.isTenantUrlLoaded((result) => {
      if (result) {
        self.loadMappings();
      }
    });
   }

  private loadMappings() {
    console.log('userMapping: will use this for getting CAD users');
    forkJoin([
      this.api.getUserMappings(),
      this.api.getCcUsers(),
      this.cadUserService.initialized$
    ]).subscribe(responses => {
        console.log('got all responses');

        this.userMappings = responses[0];
        this.ccUsers = responses[1];
        this.prepareDataToDisplay();
      },
      error => console.log('its an error', error));
  }

  private prepareDataToDisplay() {

    this.displayableMappings = this.ccUsers
      .map(aUser => {
        const existingMapping: EditableCCUser[] = this.userMappings
          .filter(mapping => mapping.uuid === aUser.uuid)
          .map(aMapping => {
            this.cadUserService.disableCadUser(aMapping.cadUserKey);
            return { user: aUser, state: RowState.View, mapping: aMapping,
              oldCadKey: aMapping.cadUserKey, selectedCadUsers: [this.cadUserService.getUserByKey(aMapping.cadUserKey)],
              selectedCadUsersSubscription: null};
          });
        return existingMapping[0] || { user: aUser, state: RowState.View, mapping:
          { userId: aUser.id, userKey: aUser.key, uuid: aUser.uuid, tenantId: null, customerId: null,
            cadUserAgencyKey: null, cadUserKey: null, cadUserName: null, whenUpdated: new Date()}, oldCadKey: null,
            selectedCadUsers: [this.cadUserService.getUserByKey(null)],
            selectedCadUsersSubscription: null};

    });
    console.log(this.displayableMappings);
    setTimeout(() => {
      this.initialized$.next(true);
    });
  }


  private prepareUserMapping(displayedUser: CCUser, newCadUser: CADUser): UserMapping {
    const newMapping: UserMapping = {
      cadUserAgencyKey: newCadUser.cadUserAgencyKey,
      cadUserKey: newCadUser.cadUserKey,
      cadUserName: newCadUser.cadUserName,
      uuid: displayedUser.uuid,
      tenantId: null,
      customerId: null,
      userId: displayedUser.id,
      userKey: displayedUser.key,
      whenUpdated: new Date()
    };
    return newMapping;
  }

  public saveUserMapping(row: EditableCCUser) {
    console.log('saving a row');
    console.log(row);
    this.updateUserMapping(row);
    row.selectedCadUsers = [this.cadUserService.getUserByKey(row.mapping.cadUserKey)];
    row.selectedCadUsersSubscription.unsubscribe();
    this.updateSaveAllButton();
  }

  public editUserMapping(row: EditableCCUser) {
    console.log('editing a row');
    console.log(row);
    row.selectedCadUsers = this.cadUserService.getCadUsers().map(_ => _);
    row.selectedCadUsersSubscription = this.cadUserService.cadUsers$.subscribe(update => {
      console.log('updating state of: ', update.cadUserKey);
      row.selectedCadUsers.filter(r => r.cadUserKey === update.cadUserKey)
        .map(found => found.state = update.state);
    });
    row.oldCadKey = row.mapping.cadUserKey;
    row.state = RowState.Edit;
    this.updateSaveAllButton();
  }

  public cancelEditUserMapping(row: EditableCCUser) {
    console.log('cancel row edit');
    console.log(row);
    row.state = RowState.View;
    row.mapping.cadUserKey = row.oldCadKey;
    row.mapping.cadUserName = row.oldCadKey ? this.cadUserService.getUserByKey(row.oldCadKey).cadUserName : null;
    row.oldCadKey = null;
    row.selectedCadUsers = [this.cadUserService.getUserByKey(row.mapping.cadUserKey)];
    row.selectedCadUsersSubscription.unsubscribe();
    this.updateSaveAllButton();
  }

  private updateSaveAllButton() {
    this.saveAllDisabled = ! this.displayableMappings.reduce((combined, single) => (single.state === RowState.Edit) || combined, false);
  }

  public saveAllMappings() {
    this.displayableMappings
      .filter((a) => a.state === RowState.Edit)
      .map((a) => this.saveUserMapping(a));
    this.updateSaveAllButton();
  }

  public cancelAllMappings() {
    this.displayableMappings
      .filter((a) => a.state === RowState.Edit)
      .map((a) => this.cancelEditUserMapping(a));
    this.updateSaveAllButton();
  }

  private updateUserMapping(currentRecord: EditableCCUser) {
    if (currentRecord.oldCadKey === currentRecord.mapping.cadUserKey) {
      console.log('Nothing changed');
      currentRecord.state = RowState.View;
      return;
    }
    console.log('update user mapping', currentRecord);
    if (currentRecord.mapping.cadUserKey) {
      const cadUser = this.cadUserService.getUserByKey(currentRecord.mapping.cadUserKey);
      if (!cadUser || cadUser.state !== CadUserState.Available) {
        console.log('CAD user is taken, ignoring the mapping', currentRecord.mapping.cadUserKey);
        return;
      }
      this.cadUserService.blockCadUser(cadUser.cadUserKey);
      const mapping = this.prepareUserMapping(
        currentRecord.user,
        cadUser
      );
      currentRecord.state = RowState.Saving;
      this.api.storeUserMapping(mapping, currentRecord.oldCadKey === null)
        .subscribe(() => {
          this.monService.logEvent('UserMapping: created');
          currentRecord.state = RowState.View;
          currentRecord.mapping = mapping;
          this.cadUserService.disableCadUser(currentRecord.mapping.cadUserKey);
          if (currentRecord.oldCadKey) {
            this.cadUserService.enableCadUser(currentRecord.oldCadKey);
          }
        }, (error) => {
          this.monService.logError('API Error', `UserMapping: create failed: ${error.status}`);
          console.log(error);
          currentRecord.state = RowState.Edit;
          this.cadUserService.releaseBlockCadUser(currentRecord.mapping.cadUserKey, CadUserState.Available);
        });
    } else {
      console.log('Delete the mapping...');
      this.cadUserService.blockCadUser(currentRecord.oldCadKey);
      currentRecord.state = RowState.Saving;
      this.api.deleteUserMappingByUuid(currentRecord.user)
        .subscribe(() => {
          this.monService.logEvent('UserMapping: deleted');
          currentRecord.state = RowState.View;
          this.cadUserService.enableCadUser(currentRecord.oldCadKey);
          currentRecord.mapping.cadUserName = currentRecord.mapping.cadUserAgencyKey = null; },
        (error) => {
          console.log(error);
          this.monService.logError('API Error', `UserMapping: delete failed: ${error.status}`);
          currentRecord.state = RowState.Edit;
          this.cadUserService.releaseBlockCadUser(currentRecord.oldCadKey, CadUserState.Unavailable);
        });
    }
  }

  public loadImportedMappings(importedMappings: any, tenantMapping: TenantMapping) {
    const importedMappingsPairs = this.prepareImportedMappingsForDisplay(importedMappings, tenantMapping)
      .reduce((pairs, current) => { pairs[current.userId] = current; return pairs; }, {});
    this.displayableMappings
      .filter((a) => (a.user.id in importedMappingsPairs))
      .filter((a) => (a.mapping.cadUserKey !== importedMappingsPairs[a.user.id].cadUserKey))
      .map((a) => {
        console.log('updating', a.user.id);
        a.oldCadKey = a.mapping.cadUserKey ? a.mapping.cadUserKey : null;
        a.mapping = importedMappingsPairs[a.user.id];
        a.state = RowState.Edit;

        a.selectedCadUsers = this.cadUserService.getCadUsers().map(_ => _);
        a.selectedCadUsersSubscription = this.cadUserService.cadUsers$.subscribe(update => {
          console.log('updating state of: ', update.cadUserKey);
          a.selectedCadUsers.filter(r => r.cadUserKey === update.cadUserKey)
            .map(found => found.state = update.state);
        });
        return a;
      });
    this.updateSaveAllButton();
    console.log('after:');
    console.log(this.displayableMappings);
  }

  private prepareImportedMappingsForDisplay(mappings: UserMapping[], tenantMapping: TenantMapping): UserMapping[] {
    // 1. get rid of users not provisioned in CCadmin
    let prevNumOfItems = mappings.length;
    const ccUserIds = this.ccUsers.reduce((output, current) => {
      output[current.id] = current.key;
      return output;
    }, {});
    let filteredMappings = mappings
      .filter((a) => a.userId in ccUserIds)
      .map(a => { a.userKey = ccUserIds[a.userId]; return a; });
    if (filteredMappings.length !== prevNumOfItems) {
      console.log(prevNumOfItems - filteredMappings.length + ' item(s) filtered out - userId not found.');
    }

    // 2. get rid of users mapped to different CAD agency
    //    and replace agencyKey with agencyAlias
    prevNumOfItems = filteredMappings.length;
    filteredMappings = filteredMappings
      .filter((a) => a.cadUserAgencyKey === tenantMapping.cadAgencyAlias) // TODO ? hack?
      .map(a => { a.cadUserAgencyKey = tenantMapping.cadAgencyKey; return a; });
    if (filteredMappings.length !== prevNumOfItems) {
      console.log(prevNumOfItems - filteredMappings.length + ' item(s) filtered out - incorrect agency.');
    }

    // 3. Populate cadUserKey, if cadUserName exists
    prevNumOfItems = filteredMappings.length;
    const caduserIds = this.cadUserService.getCadUsers().reduce((output, current) => {
      output[current.cadUserName] = current.cadUserKey;
      return output;
    }, {});
    filteredMappings = filteredMappings
      .filter((a) => a.cadUserName in caduserIds)
      .map(a => { a.cadUserKey = caduserIds[a.cadUserName]; return a; });
    if (filteredMappings.length !== prevNumOfItems) {
      console.log(prevNumOfItems - filteredMappings.length + ' item(s) filtered out - CAD user does not exist.');
    }

    console.log('filtered:');
    console.log(filteredMappings);
    return filteredMappings;
  }
}
