import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError, iif, of } from 'rxjs';
import { catchError, retryWhen, tap, delay, concatMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
export class ErrorHandlingInterceptor implements HttpInterceptor {
    constructor(private snackBar: MatSnackBar) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(this.handleRetry)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let message: string;
                    if (error.status === 403 || error.status === 401) {
                      message = 'You need to be authorized to access this page';
                    }
                    if (error.status.toString().startsWith('5')) {
                      message = 'Server error - try again later';
                    }
                    if (message) {
                      this.snackBar.open(message, 'Dismiss',  {
                        verticalPosition: 'top',
                      });
                    }
                    return throwError(error);
                })
      );
    }

  private handleRetry<T>(source: Observable<T>): Observable<T> {
    return source.pipe(
      retryWhen(errors =>
        errors.pipe(
          concatMap((e, i) =>
            iif(
              () => (i > 1 || !e.status.toString().startsWith('5')),
              throwError(e),
              of(e).pipe(
                tap(ex => console.log(`retrying request, attempt ${i + 1} due to error: ${ex.status}`)),
                delay(250)
              )
            )
          )
        )
      ));
  }
}
